import type { Tokens } from '../../../../theming/types'

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      listItem: {
        marginBlock: '8px',
        padding: 0,
      },

      title: {
        ...tokens.fontSectionHeadline,
        textTransform: 'uppercase',
        color: tokens.fontColorSubtle,
        marginBlockStart: '12px',
        marginBlockEnd: '8px',
        paddingInline: '16px',
        textAlign: 'start',
      },

      separator: {
        display: 'block',
        borderBottom: `1px solid ${tokens.borderColorSubtle}`,

        '&:last-child': {
          display: 'none',
        },
      },
    },

    motionVariants: {
      title: {
        initial: {
          opacity: 0,
        },

        open: {
          opacity: 1,
          transition: {
            ease: 'harmonize',
            duration: 0.15,
          },
        },
      },
    },
  } as const
}
