import { oliveButtonTokens } from '@ds/tokens'

const main = {
  defaultBackgroundColor: oliveButtonTokens.main.defaultBackgroundColor,
  defaultBorderColor: undefined,
  defaultTextColor: oliveButtonTokens.main.defaultTextColor,
  hoverBackgroundColor: oliveButtonTokens.main.hoverBackgroundColor,
  hoverTextColor: undefined,
  activeBackgroundColor: oliveButtonTokens.main.activeBackgroundColor,
  activeTextColor: undefined,
  disabledBackgroundColor: oliveButtonTokens.main.disabledBackgroundColor,
  disabledBorderColor: undefined,
  disabledTextColor: oliveButtonTokens.main.disabledTextColor,
  disabledOpacity: undefined,
  iconColor: undefined,
  iconHoverColor: undefined,
  buttonGroupSeparatorColor: oliveButtonTokens.main.buttonGroupSeparatorColor,
} as const

const primary = {
  defaultBackgroundColor: oliveButtonTokens.primary.defaultBackgroundColor,
  defaultBorderColor: undefined,
  defaultTextColor: oliveButtonTokens.primary.defaultTextColor,
  hoverBackgroundColor: oliveButtonTokens.primary.hoverBackgroundColor,
  hoverTextColor: undefined,
  activeBackgroundColor: oliveButtonTokens.primary.activeBackgroundColor,
  activeTextColor: undefined,
  disabledBackgroundColor: oliveButtonTokens.primary.disabledBackgroundColor,
  disabledBorderColor: undefined,
  disabledTextColor: oliveButtonTokens.primary.disabledTextColor,
  disabledOpacity: undefined,
  iconColor: undefined,
  iconHoverColor: undefined,
  buttonGroupSeparatorColor:
    oliveButtonTokens.primary.buttonGroupSeparatorColor,
} as const

const secondary = {
  defaultBackgroundColor: oliveButtonTokens.secondary.defaultBackgroundColor,
  defaultBorderColor: oliveButtonTokens.secondary.defaultBorderColor,
  defaultTextColor: oliveButtonTokens.secondary.defaultTextColor,
  hoverBackgroundColor: oliveButtonTokens.secondary.hoverBackgroundColor,
  hoverTextColor: undefined,
  activeBackgroundColor: oliveButtonTokens.secondary.activeBackgroundColor,
  activeTextColor: undefined,
  disabledBackgroundColor: oliveButtonTokens.secondary.disabledBackgroundColor,
  disabledBorderColor: oliveButtonTokens.secondary.disabledBorderColor,
  disabledTextColor: oliveButtonTokens.secondary.disabledTextColor,
  disabledOpacity: undefined,
  iconColor: oliveButtonTokens.secondary.iconColor,
  iconHoverColor: undefined,
  buttonGroupSeparatorColor:
    oliveButtonTokens.secondary.buttonGroupSeparatorColor,
} as const

const tertiary = {
  defaultBackgroundColor: oliveButtonTokens.tertiary.defaultBackgroundColor,
  defaultBorderColor: undefined,
  defaultTextColor: oliveButtonTokens.tertiary.defaultTextColor,
  hoverBackgroundColor: oliveButtonTokens.tertiary.hoverBackgroundColor,
  hoverTextColor: oliveButtonTokens.tertiary.hoverTextColor,
  activeBackgroundColor: oliveButtonTokens.tertiary.activeBackgroundColor,
  activeTextColor: oliveButtonTokens.tertiary.activeTextColor,
  disabledBackgroundColor: oliveButtonTokens.tertiary.disabledBackgroundColor,
  disabledBorderColor: undefined,
  disabledTextColor: oliveButtonTokens.tertiary.disabledTextColor,
  disabledOpacity: undefined,
  iconColor: oliveButtonTokens.tertiary.iconColor,
  iconHoverColor: oliveButtonTokens.tertiary.iconHoverColor,
  buttonGroupSeparatorColor: undefined,
} as const

const danger = {
  defaultBackgroundColor: oliveButtonTokens.danger.defaultBackgroundColor,
  defaultBorderColor: undefined,
  defaultTextColor: oliveButtonTokens.danger.defaultTextColor,
  hoverBackgroundColor: oliveButtonTokens.danger.hoverBackgroundColor,
  hoverTextColor: undefined,
  activeBackgroundColor: oliveButtonTokens.danger.activeBackgroundColor,
  activeTextColor: undefined,
  disabledBackgroundColor: oliveButtonTokens.danger.disabledBackgroundColor,
  disabledBorderColor: undefined,
  disabledTextColor: oliveButtonTokens.danger.disabledTextColor,
  disabledOpacity: undefined,
  iconColor: undefined,
  iconHoverColor: undefined,
  buttonGroupSeparatorColor: oliveButtonTokens.danger.buttonGroupSeparatorColor,
} as const

const tokens = {
  main,
  primary,
  secondary,
  tertiary,
  danger,

  invertedBackgroundColor: oliveButtonTokens.invertedBackgroundColor,
  invertedTextColor: oliveButtonTokens.invertedTextColor,

  defaultSize: oliveButtonTokens.defaultSize,
  mobileSize: oliveButtonTokens.mobileSize,
} as const

export default tokens
