import React from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from '@ds/comp-private'
import { ariaProps, dataProps, onProps } from '@ds/react-utils'
import { Motion } from '@ds/motion'
import { ConditionalTag } from '../../internal/components/ConditionalTag'
import { requiredPropMessage, consoleWarn } from '../../logging'
import { CustomPropTypes } from '../../support'
import { useThemeStyles } from '../../theming'
import { variant } from '../../utilities'
import {
  IconOrImage,
  iconWithImageKinds,
} from '../../internal/components/IconOrImage'
import { Caret } from '../../internal/components/Caret'
import type {
  AnchorOrButtonForwardRef,
  AriaAttributes,
  EventListenerProps,
  IconPosition,
  LabelForwardRef,
  OliveImageIcon,
  SystemIconKey,
} from '../../types'
import { AnchorTarget, anchorTargets } from '../../variables'
import styles from './styles'

export const buttonIcons = iconWithImageKinds
export const buttonIconPositions = ['beforeText', 'afterText'] as const
export const buttonKinds = [
  'main',
  'primary',
  'secondary',
  'tertiary',
  'danger',
] as const
export const buttonSizes = ['xlarge', 'large', 'medium', 'small'] as const
export const buttonTypes = ['button', 'submit', 'reset'] as const

export type ButtonKind = (typeof buttonKinds)[number]
export type ButtonSize = (typeof buttonSizes)[number]
export type ButtonType = (typeof buttonTypes)[number]

export const OLIVE_BUTTON_KINDS = buttonKinds
export const INK_BUTTON_KINDS = ['primary', 'secondary', 'tertiary'] as const
export interface ButtonProps
  extends EventListenerProps<
      HTMLButtonElement | HTMLAnchorElement | HTMLInputElement
    >,
    AriaAttributes {
  /**
   * The text to present to assistive devices in order to identify the Button.
   * `accessibilityText` replaces the visible `text` for screen reader users.
   *
   * (!) At least one of the props 'text' or 'accessibilityText' is required.
   */
  accessibilityText?: string
  /**
   * Manually applies an active state to the button style.
   */
  active?: boolean
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
  /**
   * Applies the 'disabled' attribute.
   */
  disabled?: boolean
  /**
   * The provided component will display at the end of the Button.
   */
  endElement?: React.ReactElement
  /**
   * A React ref to assign to the HTML node representing the Button element.
   */
  forwardedRef?: AnchorOrButtonForwardRef | LabelForwardRef
  /**
   * Forces the Button to fill the full width of its container.
   *
   * Note that other styles (height, padding, etc.) are still defined by the
   * value of the `size` prop.
   */
  fullWidth?: boolean
  /**
   * Hides the "down arrow icon" that is automatically applied when a Button
   * is passed as the value of the 'trigger' prop of a Menu element.
   */
  hideTrigger?: boolean
  /**
   * URL for navigating. If a URL is supplied it renders as an anchor element, if not,
   * a button element.
   */
  href?: string
  /**
   * The Icon to show inside the Button.
   * @deprecated See `startElement` or `endElement`.
   */
  icon?: SystemIconKey | OliveImageIcon
  /**
   * Position the supplied icon before or after the Button text.
   * @deprecated See `startElement` or `endElement`.
   */
  iconPosition?: IconPosition
  /**
   * Inverts the Button colors (for an applicable Button.kind).
   */
  inverted?: boolean
  /**
   * The kind of the Button.
   */
  kind?: ButtonKind
  /**
   * Display a loading indicator in the button. Note: cannot be used with an additional icon.
   */
  loading?: boolean
  /**
   * Displays a "down arrow icon" inside the Button (placed to the right of
   * any text that is present). Use this prop when clicking the Button will
   * display a menu beneath it.
   *
   * (!) Applying this prop will also add the HTML attribute aria-haspopup="true"
   * to the rendered button element.
   */
  menuTrigger?: boolean
  /**
   * Accepts attributes matching the pattern on[A-Z].* in order to register event handlers.
   */
  'on[A-Z].*'?: React.EventHandler<
    React.SyntheticEvent<
      HTMLButtonElement | HTMLAnchorElement | HTMLInputElement
    >
  >
  /**
   * The function to call when a 'click' event is fired.
   */
  onClick?: React.MouseEventHandler<
    HTMLButtonElement | HTMLAnchorElement | HTMLInputElement
  >
  /**
   * Display the Button with rounded ends (i.e. "pill" shaped).
   */
  pill?: boolean
  /**
   * The relationship of the linked URL of an anchor as space-separated link types.
   *
   * Reference: https://developer.mozilla.org/en-US/docs/Web/HTML/Link_types
   */
  rel?: string
  /**
   * The aria role to be added to the Button
   */
  role?: string
  /**
   * The size of the Button. (Olive only)
   */
  size?: ButtonSize
  /**
   * The provided component will display at the start of the Button.
   */
  startElement?: React.ReactElement
  /**
   * The HTML link target to use when an href is given.
   */
  target?: AnchorTarget
  /**
   * The text to be displayed inside of the Button.
   *
   * (!) At least one of the props 'text' or 'accessibilityText' is required.
   */
  text?: string
  /**
   * The title global attribute contains text representing advisory information related to the element it belongs to.
   */
  title?: string
  /**
   * The HTML 'type' attribute to apply to the rendered button element.
   */
  type?: ButtonType
  /**
   * @ignore @internal Subject to change. Do not use outside of this repository.
   * Sets up button to be able to animate in SideNav. Used in SideNav primary and secondary buttons.
   */
  animate?: boolean
  /**
   * @ignore @internal Subject to change. Do not use outside of this repository.
   * Starts the button animation. Used in SideNav primary and secondary buttons.
   */
  animateOn?: boolean
  /** @ignore */
  id?: string
  /** @ignore @deprecated Use `aria-expanded` instead. */
  expanded?: boolean
  /**
   * @ignore @internal Subject to change. Do not use outside of this repository.
   * Used to set styles for each button in a group.
   */
  groupStart?: boolean
  /**
   * @ignore @internal Subject to change. Do not use outside of this repository.
   * Used to set styles for each button in a group.
   */
  groupCenter?: boolean
  /**
   * @ignore @internal Subject to change. Do not use outside of this repository.
   * Used to set styles for each button in a group.
   */
  groupEnd?: boolean
  /**
   * @ignore @internal Subject to change. Do not use outside of this repository.
   * Used to set styles for a round buttons.
   */
  round?: boolean
  /**
   * @ignore @internal Subject to change. Do not use outside of this repository.
   * If true the button will be a file input.
   */
  fileInput?: boolean
  /**
   * @ignore @internal Subject to change. Do not use outside of this repository.
   * Passed to the underlying <input> element when `fileInput` is used.
   * @link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept
   */
  accept?: string
  /**
   * @ignore @internal Subject to change. Do not use outside of this repository.
   * Passed to the underlying <input> element when `fileInput` is used.
   * @link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#attr-multiple
   */
  multiple?: boolean
}

/**
 * Buttons give users a simple and direct way to take an action.
 */
export function Button({
  accessibilityText,
  active = false,
  animate = false,
  animateOn = false,
  'data-qa': dataQa,
  disabled = false,
  endElement,
  fileInput,
  forwardedRef,
  fullWidth = false,
  hideTrigger = false,
  href = '',
  icon,
  iconPosition = 'beforeText',
  inverted = false,
  kind = 'secondary',
  loading = false,
  menuTrigger = false,
  onClick,
  pill,
  rel,
  role,
  size = 'medium',
  startElement,
  target,
  text = '',
  title,
  type = 'button',
  ...restProps
}: ButtonProps) {
  const translate = useTranslate()

  const sx = useThemeStyles(styles, {
    animate,
    disabled,
    loading,
    text,
  })

  if (!(text || accessibilityText)) {
    requiredPropMessage({
      component: 'Button',
      prop1: 'text',
      prop2: 'accessibilityText',
    })
  }

  if (loading && icon) {
    consoleWarn(`
      When using a button component, both the loading and icon props cannot
      be used at the same time. The icon prop will take precendence.
    `)
  }

  if (icon) {
    consoleWarn(
      `The "icon" and "iconPosition" props have been deprecated in favor of "startElement" and "endElement". Instead of passing a string for an icon kind, pass an icon React component from '@ds/icons' into the "startElement" or "endElement" prop.`,
    )
  }

  const sizeVariant = variant('size', size)

  const activeStyles = active && sx.button[kind].active
  const groupStartStyles = restProps.groupStart && sx.button.groupStart
  const groupCenterStyles = restProps.groupCenter && {
    ...sx.button.groupCenter,
    ...sx.button[kind].buttonSeparatorColor,
  }
  const groupEndStyles = restProps.groupEnd && {
    ...sx.button.groupEnd,
    ...sx.button[kind].buttonSeparatorColor,
  }

  const invertedStyles = inverted && [
    sx.button.inverted,
    // @ts-expect-error
    sx.button.inverted?.[kind],
  ]

  const pillStyles = pill && sx.pill
  const roundStyles = restProps.round && sx.button.round
  const triggerStyles = menuTrigger && !hideTrigger && sx.menuTrigger
  const noTextStyles = !text && sx.hideText
  const iconButtonStyles = (startElement || endElement || loading || icon) && [
    icon && sx.iconButton?.[iconPosition],
    (startElement || loading) && sx.iconButton?.beforeText,
    endElement && sx.iconButton?.afterText,
  ]

  const buttonStyles = [
    sx.button.default,
    sx.button[kind].default,
    sx.button[sizeVariant],
    activeStyles,
    pillStyles,
    roundStyles,
    iconButtonStyles,
    // For CSS specificity, triggerStyles needs to come after Icon styles for buttons that have both an Icon and MenuTrigger.
    animate && !animateOn ? null : triggerStyles,
    invertedStyles,
    noTextStyles,
    groupStartStyles,
    groupCenterStyles,
    groupEndStyles,
  ]

  const iconStyles = (position: IconPosition) => [
    sx.icon.default,
    sx.icon[position],
    restProps.round && sx.icon.round,
    !text && sx.icon.hideText,
  ]

  const iconNode = icon && (
    <span css={iconStyles(iconPosition)} data-qa={dataQa && `${dataQa}-icon`}>
      <IconOrImage kind={icon} />
    </span>
  )

  const opensNewWindow = !fileInput && target === '_blank' && !!href

  const startElementNode =
    (startElement && (
      <span
        css={iconStyles('beforeText')}
        data-qa={dataQa && `${dataQa}-start`}
      >
        {startElement}
      </span>
    )) ||
    (iconPosition === 'beforeText' && iconNode)

  const endElementNode =
    (endElement && (
      <span css={iconStyles('afterText')} data-qa={dataQa && `${dataQa}-end`}>
        {endElement}
      </span>
    )) ||
    (iconPosition === 'afterText' && iconNode)

  const caretNode = menuTrigger && !hideTrigger && (
    <span css={sx.caret} data-qa={dataQa && `${dataQa}-trigger`}>
      <Caret />
    </span>
  )

  const textSpan = text && (
    <span
      css={[sx.button.text, restProps.round && sx.hidden]}
      data-qa={dataQa && `${dataQa}-text`}
    >
      {text}
    </span>
  )

  const spinnerNode = loading && (
    <span css={sx.loadingSpinner} data-qa={dataQa && `${dataQa}-loading`} />
  )

  // used for SideNav
  const animationNode = (
    <Motion layout as="span" data-animating={animateOn} css={sx.animation}>
      <Motion
        layout
        as="span"
        transition={{ duration: 0.25 }}
        css={sx.animation.inner}
      >
        {/* if expanded and menuTrigger */}
        {animateOn && menuTrigger && !hideTrigger
          ? undefined
          : spinnerNode || startElementNode}
        <Motion
          as="span"
          animate={{ opacity: animateOn ? 1 : 0 }}
          transition={{ duration: 0.25 }}
        >
          {textSpan}
        </Motion>
        {/* only show caret or endElement when expanded */}
        {animateOn ? caretNode || endElementNode : undefined}
      </Motion>
    </Motion>
  )

  const buttonAriaProps = ariaProps(restProps)
  const additionalAttributes = !href && {
    'aria-haspopup':
      menuTrigger || (buttonAriaProps['aria-haspopup'] as boolean | undefined),
    'aria-expanded':
      restProps.expanded ||
      (buttonAriaProps['aria-expanded'] as boolean | undefined),
  }
  const buttonHelperClasses = 'olv-button olv-ignore-transform'

  let ariaLabel = opensNewWindow
    ? translate('OLIVE:LINK_OPENS_IN_NEW_WINDOW:A11Y', {
        LINK: accessibilityText || text,
      })
    : accessibilityText

  ariaLabel = loading
    ? `${ariaLabel || text}, ${translate('loading')}`
    : ariaLabel

  if (fileInput) {
    const { accept, id, multiple, onChange } = restProps

    const labelStyles = [
      buttonStyles,
      fullWidth && sx.button.fullWidth,
      disabled && sx.button.fileInputDisabled,
    ]

    return (
      <>
        <input
          {...dataProps(restProps)}
          aria-label={ariaLabel}
          accept={accept}
          css={sx.fileInput.input}
          data-qa={dataQa}
          disabled={disabled}
          id={id}
          multiple={multiple}
          onChange={onChange}
          onClick={onClick as React.MouseEventHandler<HTMLInputElement>}
          type="file"
        />
        <label
          {...onProps(restProps)}
          className={buttonHelperClasses}
          css={labelStyles}
          htmlFor={id}
          ref={forwardedRef as LabelForwardRef}
          data-qa={dataQa && `${dataQa}-label`}
        >
          {startElementNode}
          {textSpan}
          {endElementNode}
        </label>
      </>
    )
  }

  return (
    <ConditionalTag
      aria-label={ariaLabel}
      {...ariaProps(restProps)}
      {...dataProps(restProps)}
      {...onProps(restProps)}
      {...additionalAttributes}
      aria-live={loading ? 'polite' : undefined}
      className={buttonHelperClasses}
      css={[buttonStyles, fullWidth && sx.button.fullWidth]}
      data-qa={dataQa}
      disabled={disabled}
      forceElement="button"
      forwardedRef={forwardedRef}
      href={href}
      id={restProps.id}
      onClick={onClick}
      rel={rel}
      role={role}
      target={target}
      title={title}
      type={type}
    >
      {animate ? (
        animationNode
      ) : (
        <>
          {spinnerNode || startElementNode}
          {textSpan}
          {caretNode || endElementNode}
        </>
      )}
    </ConditionalTag>
  )
}

Button.icons = buttonIcons
Button.iconPositions = buttonIconPositions
Button.kinds = buttonKinds
Button.sizes = buttonSizes
Button.targets = anchorTargets
Button.types = buttonTypes

Button.propTypes = {
  accessibilityText: PropTypes.string,
  active: PropTypes.bool,
  animate: PropTypes.bool,
  animateOn: PropTypes.bool,
  'data-.*': PropTypes.string,
  disabled: PropTypes.bool,
  endElement: PropTypes.element,
  forwardedRef: CustomPropTypes.ReactRef,
  fullWidth: PropTypes.bool,
  hideTrigger: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.oneOf(buttonIcons),
  iconPosition: PropTypes.oneOf(buttonIconPositions),
  inverted: PropTypes.bool,
  kind: PropTypes.oneOf(buttonKinds),
  loading: PropTypes.bool,
  menuTrigger: PropTypes.bool,
  'on[A-Z].*': PropTypes.func,
  onClick: PropTypes.func,
  pill: PropTypes.bool,
  rel: PropTypes.string,
  role: PropTypes.string,
  size: PropTypes.oneOf(buttonSizes),
  startElement: PropTypes.element,
  target: PropTypes.oneOf(anchorTargets),
  text: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.oneOf(buttonTypes),
}

Button.displayName = 'Button'
