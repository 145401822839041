import type { ButtonKind } from '../components/Button'
import type { ButtonTokens } from '../theming/types'

type CustomBrandingOpacity = 0.4
type SystemBrandingOpacity = 0.25 | '0.25' | '0.4' | undefined

type ButtonKindTokens = Omit<ButtonTokens[ButtonKind], 'disabledOpacity'> & {
  disabledOpacity: CustomBrandingOpacity | SystemBrandingOpacity
}

const CreateButtonCss = (tokens: ButtonKindTokens) => {
  const {
    activeBackgroundColor,
    defaultBackgroundColor,
    defaultBorderColor,
    defaultTextColor,
    hoverBackgroundColor,
    iconColor,
  } = tokens

  const activeStyles = {
    backgroundColor: activeBackgroundColor,
  }

  return {
    default: {
      backgroundColor: defaultBackgroundColor,
      borderColor: defaultBorderColor,
      color: defaultTextColor,

      ...(iconColor && {
        '& svg': {
          fill: iconColor,
        },
      }),

      '&:hover': {
        backgroundColor: hoverBackgroundColor,
        color: defaultTextColor,
      },

      '&:active, &[aria-expanded="true"]': activeStyles,

      '&:disabled': {
        backgroundColor: defaultBackgroundColor,
      },
    },

    active: activeStyles,

    // These styles are only used in FileInput Component
    disabled: {
      '&:hover': {
        backgroundColor: defaultBackgroundColor,
      },
    },

    // These styles are only used in ButtonGroup and the ComboButton components
    buttonSeparatorColor: {
      borderInlineStartColor: defaultTextColor,
    },
  }
}

export default CreateButtonCss
