import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { MotionVariant } from '@ds/motion'
import { dataProps, useUniqueId } from '@ds/react-utils'
import { useThemeStyles } from '../../../theming'
import type { ConditionalReactElementChildOrArray } from '../../../types'
import styles from './styles'
import { MenuContext } from '../MenuContext'

export interface MenuGroupProps {
  /**
   * The title to present to assistive devices in order to identify the MenuGroup
   * @deprecated accessibilityTitle is no longer used, if a `title` is provided it will be used as the accessible name
   */
  accessibilityTitle?: string
  /**
   * The 'children' prop accepts ReactElements. Usage of an HTML element will result in a console error.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: ConditionalReactElementChildOrArray<any>
  /**
   * The section header for the group of items.
   */
  title?: string
  /**
   * Accepts custom data attributes.
   */
  'data-.*'?: string
  'data-qa'?: string
}

export function MenuGroup({
  children,
  title,
  'data-qa': dataQa,
  ...restProps
}: MenuGroupProps) {
  const sx = useThemeStyles(styles)
  const menuContext = useContext(MenuContext)
  const groupId = useUniqueId('short')

  const menuTitle = title && (
    <MotionVariant
      css={sx.default.title}
      className="olv-menu-group olv-ignore-transform"
      data-qa={dataQa && `${dataQa}-title`}
      id={groupId}
      variants={sx.motionVariants.title}
    >
      {title}
    </MotionVariant>
  )

  function renderChildren() {
    const childHasStartElementIcons = React.Children.toArray(children).some(
      (child) =>
        React.isValidElement(child) &&
        (child.props?.startElement?.type?.displayName === 'Icon' ||
          child.props?.startElement?.type?.name?.includes('Svg')),
    )

    return React.Children.map(children, (child) => {
      if (!React.isValidElement(child)) return null
      return React.cloneElement(child as React.ReactElement, {
        hasStartElementIcon: childHasStartElementIcons,
      })
    })
  }

  return (
    <>
      <div
        data-qa={dataQa}
        {...dataProps(restProps)}
        aria-labelledby={
          title && menuContext.MenuGroupRole ? groupId : undefined
        }
        css={sx.default.listItem}
        role={menuTitle && menuContext.MenuGroupRole}
      >
        {menuTitle}
        {renderChildren()}
      </div>

      <MotionVariant
        as="span"
        css={sx.default.separator}
        role="separator"
        variants={sx.motionVariants.title}
      />
    </>
  )
}

MenuGroup.propTypes = {
  accessibilityTitle: PropTypes.string,
  children: PropTypes.node.isRequired,
  'data-.*': PropTypes.string,
  title: PropTypes.string,
}

MenuGroup.displayName = 'Menu.Group'
