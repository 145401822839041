import {
  LocalePolicy,
  LocalePolicyMetaData,
  SupportedPropertyName,
  EnumDateFormat,
  EnumTimeFormat,
  EnumPositiveFormat,
  EnumNegativeFormat,
  EnumCurrencyCode,
} from '../types'
import {
  formatCurrency,
  formatNumber,
  currencyISOtoSymbol,
} from './currencyFormat'
import {
  dateTimeFormatToString,
  getDateFormat,
  getTimeFormat,
  timeZones,
} from './dateTimeFormat'
import {
  setTelemetry,
  reportException,
  getPerformance,
} from '../telemetry/telemetry'
import { metaData } from './metaData.data'

function _getMetaData(
  localePolicy: LocalePolicy | null,
  prop: SupportedPropertyName,
  withunset?: boolean,
  option?: Date
): LocalePolicyMetaData[] {
  const meta = metaData[prop]
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: any[] = []
  let j
  let format
  let display
  // eslint-disable-next-line no-param-reassign
  if (!localePolicy) localePolicy = {}
  const policy: LocalePolicy = {
    cultureName: (localePolicy.cultureName || 'en').toLowerCase(),
    effectiveDateFormat: '' as EnumDateFormat,
    effectiveTimeFormat: '' as EnumTimeFormat,
    effectiveCalendarType:
      localePolicy.effectiveCalendarType ||
      localePolicy.calendarType ||
      'gregorian',
    effectiveCurrencyCode:
      localePolicy.effectiveCurrencyCode || localePolicy.currencyCode || 'usd',
    effectiveCurrencyPositiveFormat: '' as EnumPositiveFormat,
    effectiveCurrencyNegativeFormat: '' as EnumNegativeFormat,
  }
  if (withunset) {
    result.push({ value: 'unset', display: 'Unset' })
  }
  if (prop === 'timeFormat') {
    for (j = 0; j < meta.length; j++) {
      policy.effectiveTimeFormat = meta[j].value
      format = getTimeFormat(policy, false)
      if (!format) format = 'None'
      display = dateTimeFormatToString(
        format,
        option as Date,
        policy.cultureName as string,
        false
      )
      if (format !== 'None') {
        display = display + ' (' + format + ')'
      }
      result.push({ value: meta[j].value, display })
    }
  } else if (prop === 'timeZone') {
    for (j = 0; j < timeZones.length; j++) {
      result.push(timeZones[j])
    }
  } else if (prop === 'dateFormat') {
    let isJapanese = false
    if (policy.effectiveCalendarType === 'japanese') isJapanese = true
    for (j = 0; j < meta.length; j++) {
      policy.effectiveDateFormat = meta[j].value
      format = getDateFormat(policy, false)
      display = dateTimeFormatToString(
        format,
        option as Date,
        policy.cultureName as string,
        isJapanese
      )
      display = display + ' (' + format + ')'
      result.push({ value: meta[j].value, display })
    }
  } else if (prop === 'datetimeFormat') {
    for (j = 0; j < meta.length; j++) {
      format = meta[j].format[policy.cultureName] || meta[j].format['_']
      display = dateTimeFormatToString(
        format,
        option as Date,
        policy.cultureName as string,
        false
      )
      result.push({ ...meta[j], display })
    }
  } else if (prop === 'currencyPositiveFormat') {
    for (j = 0; j < meta.length; j++) {
      policy.effectiveCurrencyPositiveFormat = meta[j].value
      result.push({
        value: meta[j].value,
        display: formatCurrency(
          policy,
          123456789,
          policy.effectiveCurrencyCode as EnumCurrencyCode,
          false
        ),
      })
    }
  } else if (prop === 'currencyNegativeFormat') {
    for (j = 0; j < meta.length; j++) {
      policy.effectiveCurrencyNegativeFormat = meta[j].value
      result.push({
        value: meta[j].value,
        display: formatCurrency(
          policy,
          -123456789,
          policy.effectiveCurrencyCode as EnumCurrencyCode,
          false
        ),
      })
    }
  } else if (prop === 'positiveFormat') {
    for (j = 0; j < meta.length; j++) {
      policy.effectiveCurrencyPositiveFormat = meta[j].value
      result.push({
        value: meta[j].value,
        display: formatNumber(policy, 123456789, 2),
      })
    }
  } else if (prop === 'negativeFormat') {
    for (j = 0; j < meta.length; j++) {
      policy.effectiveCurrencyNegativeFormat = meta[j].value
      result.push({
        value: meta[j].value,
        display: formatNumber(policy, -123456789, 2),
      })
    }
  } else if (prop === 'currencyCode') {
    for (const key in currencyISOtoSymbol) {
      result.push({
        value: key,
        display: currencyISOtoSymbol[key],
      })
    }
  } else {
    for (j = 0; j < meta.length; j++) {
      result.push({ value: meta[j].value, display: meta[j].display })
    }
  }
  return result
}

export { getMetaData }

function getMetaData(
  localePolicy: LocalePolicy | null,
  prop: SupportedPropertyName,
  withunset?: boolean,
  option?: Date
): LocalePolicyMetaData[] {
  try {
    const t0 = getPerformance()
    const result = _getMetaData(localePolicy, prop, withunset, option)
    setTelemetry(getMetaData.name, t0)
    return result
  } catch (ex) {
    reportException(getMetaData.name, ex)
    throw ex
  }
}
