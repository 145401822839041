import type { Tokens } from '../../../../theming/types'
import { styles } from '../../../../utilities'

const badgeStyles = (size: string) => ({
  badge: {
    height: size,
    width: size,
  },
})

export default (config: { tokens: Tokens }) => {
  const { tokens } = config
  return {
    default: {
      badge: {
        backgroundColor: tokens.statusBgColorAlert,
        border: `2px solid ${tokens.borderColorEmphasisInverse}`,
        boxSizing: 'content-box',
        borderRadius: '50%',
        display: 'inline-flex',
      },
    },

    sizeXxlarge: badgeStyles('20px'),
    sizeXlarge: badgeStyles('18px'),
    sizeLarge: badgeStyles('12px'),
    sizeMedium: badgeStyles('10px'),
    sizeSmall: badgeStyles('8px'),

    hidden: styles.visuallyHidden,
  } as const
}
