export default () =>
  ({
    default: {
      title: {
        letterSpacing: '0.8px',
        marginBlockStart: '16px',
        paddingInline: '24px',
      },
    },
  }) as const
