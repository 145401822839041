import React, { useEffect, useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import {
  useEventListener,
  lazyDocument,
  useHtmlDir,
  useUniqueId,
} from '@ds/react-utils'
import { keyboardNavigableElements, EscapeToClose } from '../../../utilities'
import { keyboardEventKeys } from '../../../variables'

const FIRST_MENU_ITEM = 'FIRST_MENU_ITEM'
const LAST_MENU_ITEM = 'LAST_MENU_ITEM'

/**
 * The menuItemEventHandler function adds a onClose function to
 * allow the onClick/onChange handler of a MenuItem to close the Menu
 *
 * The consumer provides a function accepting an event and closeMenu
 * function and it returns a standard event handler: (event) => void
 */
export type ChangeHandlerWithCloseMenu<T = Element> = (
  event: React.ChangeEvent<T>,
  closeMenu: () => void,
) => void

export type MouseHandlerWithCloseMenu<T = Element> = (
  event: React.MouseEvent<T>,
  closeMenu: () => void,
) => void

export declare function MenuItemEventHandler<T>(
  eventHandler: MouseHandlerWithCloseMenu<T>,
): React.MouseEventHandler<T>

export declare function MenuItemEventHandler<T>(
  eventHandler: ChangeHandlerWithCloseMenu<T>,
): React.ChangeEventHandler<T>

export interface MenuButtonProps {
  /**
   * The `Menu.Button` 'children' prop is a render prop.
   *
   * It accepts a function returning JSX that should contain both:
   * - a Menu element
   * - a focusable element that will display the Menu when activated; this element
   *   must have 'onClick', 'onKeyDown', and 'ref' attributes
   *
   * The render function will be called with the following arguments:
   * - buttonOnClick {function}: this should be assigned to the 'onClick' attribute
   *   of the element that will display the Menu when activated
   * - buttonOnKeyDown {function}: this should be assigned to the 'onKeyDown' attribute
   *   of the element that will display the Menu when activated
   * - buttonRef {React ref object}: this should be assigned to the 'ref' attribute
   *   of the element that will display the Menu when activated
   * - menuVisible {boolean}: this should be assigned to the 'visible' attribute of
   *   the Menu element
   * - menuAnchor {HTMLElement | null}: this should be assigned to the 'anchor' attribute of
   *   the Menu element
   * - menuRef {React ref object}: this should be assigned to the 'ref' attribute
   *   of the Menu element
   * - menuItemOnKeyDown {function}: this should be assigned to the 'onKeyDown' attribute
   *   of each `Menu.Item` element
   * - menuItemEventHandler {function}: this function should wrap the primary event handler
   *   of each `Menu.Item` (via either the 'onClick' or 'onChange' attribute)
   *   (^ read more about this special function below)
   * - menuOnVisible {function}: this function should be assigned to the 'onVisible'
   *   attribute of the Menu element
   * - menuItemOnMouseEnter {function}:this should be assigned to the 'onMouseEnter' attribute
   *   of each `Menu.Item` element
   * - activeMenuItem {Element}: the active menu item
   *
   * ===================
   *
   * re: menuItemEventHandler
   *
   * This function should be called with the primary event handler for a `Menu.Item`
   * passed as its only argument. The return value of this function is a new event handler
   * that should be assigned to the appropriate attribute of the `Menu.Item` ('onClick' or
   * 'onChange').
   *
   * The wrapped event handler will be called with two arguments:
   * - the original triggered DOM event
   * - a callback that will close the Menu when invoked
   *
   * As part of the original handler's logic, one of the tasks that it performs
   * should be to invoke the provided callback argument in order to close the Menu.
   *
   * @example
   *
   * const originalEventHandler = (event, closeMenu) => {
   *   // calculate, set state, etc.
   *   doSomething(event)
   *
   *   // invoke the provided callback in order to close the Menu
   *   closeMenu()
   * }
   *
   * const wrappedEventHandler = menuItemEventHandler(originalEventHandler)
   *
   * <Menu.Item
   *   text="My Menu.Item"
   *   onClick={wrappedEventHandler}
   * />
   *
   */
  children: (renderData: {
    buttonOnClick: () => void
    buttonOnKeyDown: (
      event: React.KeyboardEvent<HTMLAnchorElement | HTMLButtonElement>,
    ) => void
    buttonRef: React.RefObject<HTMLAnchorElement & HTMLButtonElement>
    menuVisible: boolean
    menuAnchor: HTMLElement | null
    menuRef: React.RefObject<HTMLDivElement>
    menuItemOnKeyDown: (evt: React.KeyboardEvent<HTMLElement>) => void
    menuItemEventHandler: typeof MenuItemEventHandler
    menuOnVisible: () => void
    menuItemOnMouseEnter: (evt: React.MouseEvent<HTMLElement>) => void
    activeMenuItem: HTMLElement | null
  }) => React.ReactElement
  /**
   * When true, the first `Menu.Item` that has `selected=true` will be focused when
   * the menu becomes visible.
   */
  focusSelectedOnVisible?: boolean
  /**
   * keyboardDirection changes the keyboard bindings for the trigger.
   *
   * The default direction is `vertical` meaning that pressing ArrowDown
   * will open the menu and focus the first menu item. This is suitable
   * for "dropdown" style menus.
   *
   * When in `horizontal` mode pressing ArrowRight will on the button will
   * open the menu and select the first item. Pressing ArrowLeft while
   * the menu is open will close the menu. This is suitable for nested
   * menus where they will open to the side. The keys are flipped when in RTL.
   *
   * Note: `horizontal` only controls the key bindings, it does not force the
   * menu to open horizontally
   */
  keyboardDirection?: 'horizontal' | 'vertical'
}

export function MenuButton(props: MenuButtonProps) {
  const {
    children,
    focusSelectedOnVisible = false,
    keyboardDirection = 'vertical',
  } = props

  /**
   * This stores the last hovered Menu.Item for use with nested
   * menus.  Nested menus open when their trigger is hovered and
   * remain open until a sibling Menu.Item is mouse enter'ed.
   */
  const [activeMenuItem, setActiveMenuItem] = useState<HTMLElement | null>(null)
  const [menuVisible, setMenuVisible] = useState(false)

  const menuInitialFocus = useRef('')
  const menuRef = useRef<HTMLDivElement>(null)
  const buttonRef = useRef<HTMLAnchorElement & HTMLButtonElement>(null)
  const menuAnchor = buttonRef.current
  const buttonId = useUniqueId('short')

  const textDirection = useHtmlDir()
  const isRTL = textDirection === 'rtl'

  const buttonOnClick = () => {
    /**
     * NVDA sends click events when the user presses enter in browse
     * mode. It's required to switch the focus to automatically
     * switch NVDA into focus mode, enabling proper menu navigation.
     */
    menuInitialFocus.current = FIRST_MENU_ITEM
    setMenuVisible(!menuVisible)
  }

  const focusMenuItem = (
    menuInitialFocusRef: React.MutableRefObject<string>,
  ) => {
    if (menuRef.current) {
      const keyboardNavigableEls = keyboardNavigableElements(menuRef.current)
      const firstItem = keyboardNavigableEls[0]
      const lastItem = keyboardNavigableEls[keyboardNavigableEls.length - 1]

      switch (menuInitialFocusRef.current) {
        case FIRST_MENU_ITEM:
          if (firstItem) {
            firstItem.focus()
          }
          break

        case LAST_MENU_ITEM:
          if (lastItem) {
            lastItem.focus()
          }
          break

        default:
      }
    }
  }

  const buttonOnKeyDown = (
    event: React.KeyboardEvent<HTMLAnchorElement | HTMLButtonElement>,
  ) => {
    const keyboardNavigableEls = menuRef.current
      ? keyboardNavigableElements(menuRef.current)
      : []

    switch (event.key) {
      case keyboardEventKeys.Enter:
      case keyboardEventKeys.Space:
        event.preventDefault()
        menuInitialFocus.current = FIRST_MENU_ITEM

        if (keyboardDirection === 'horizontal') {
          setActiveMenuItem(event.currentTarget)
        }

        setMenuVisible(!menuVisible)
        break

      case keyboardEventKeys.ArrowDown:
        if (keyboardDirection === 'vertical') {
          event.preventDefault()
          menuInitialFocus.current = FIRST_MENU_ITEM

          if (menuVisible) {
            focusMenuItem(menuInitialFocus)
          }

          setMenuVisible(true)
        }
        break

      case keyboardEventKeys.ArrowUp:
        if (keyboardDirection === 'vertical') {
          event.preventDefault()
          menuInitialFocus.current = LAST_MENU_ITEM

          if (menuVisible) {
            focusMenuItem(menuInitialFocus)
          }

          setMenuVisible(true)
        }
        break

      case !isRTL && keyboardEventKeys.ArrowRight:
        if (keyboardDirection === 'horizontal') {
          event.preventDefault()
          menuInitialFocus.current = FIRST_MENU_ITEM

          if (menuVisible) {
            focusMenuItem(menuInitialFocus)
          }

          setMenuVisible(true)
        }
        break
      case isRTL && keyboardEventKeys.ArrowLeft:
        if (keyboardDirection === 'horizontal') {
          event.preventDefault()
          menuInitialFocus.current = FIRST_MENU_ITEM

          if (menuVisible) {
            focusMenuItem(menuInitialFocus)
          }

          setMenuVisible(true)
        }
        break

      case keyboardEventKeys.Tab:
        keyboardNavigableEls.forEach((el) => el.setAttribute('tabindex', '-1'))
        setMenuVisible(false)
        break

      default:
    }
  }

  const menuItemEventHandler =
    (wrappedHandler?: ChangeHandlerWithCloseMenu | MouseHandlerWithCloseMenu) =>
    (event: React.ChangeEvent & React.MouseEvent) => {
      if (event.persist) {
        event.persist()
      }

      const menuItemCloseMenu = () => {
        buttonRef.current!.focus()
        setMenuVisible(false)
      }

      wrappedHandler?.(event, menuItemCloseMenu)
    }

  const menuItemOnKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    const keyboardNavigableEls = keyboardNavigableElements(menuRef.current!)
    const currentIndex = keyboardNavigableEls.indexOf(event.currentTarget)

    if (currentIndex >= 0) {
      const arrowDownIndex =
        currentIndex === keyboardNavigableEls.length - 1 ? 0 : currentIndex + 1

      const arrowUpIndex =
        currentIndex === 0 ? keyboardNavigableEls.length - 1 : currentIndex - 1

      switch (event.key) {
        case keyboardEventKeys.ArrowDown:
          event.preventDefault()
          keyboardNavigableEls[arrowDownIndex].focus()
          break

        case keyboardEventKeys.ArrowUp:
          event.preventDefault()
          keyboardNavigableEls[arrowUpIndex].focus()
          break

        case !isRTL && keyboardEventKeys.ArrowLeft:
          if (keyboardDirection === 'horizontal') {
            keyboardNavigableEls.forEach((el) =>
              el.setAttribute('tabindex', '-1'),
            )
            if (buttonRef.current) {
              buttonRef.current.focus()
            }
            setMenuVisible(false)
          }
          break
        case isRTL && keyboardEventKeys.ArrowRight:
          if (keyboardDirection === 'horizontal') {
            keyboardNavigableEls.forEach((el) =>
              el.setAttribute('tabindex', '-1'),
            )
            if (buttonRef.current) {
              buttonRef.current.focus()
            }
            setMenuVisible(false)
          }
          break

        case keyboardEventKeys.Tab:
          keyboardNavigableEls.forEach((el) =>
            el.setAttribute('tabindex', '-1'),
          )
          buttonRef.current!.focus()
          setMenuVisible(false)
          break

        case keyboardEventKeys.End:
          event.preventDefault()
          keyboardNavigableEls[keyboardNavigableEls.length - 1].focus()
          break

        case keyboardEventKeys.Home:
          event.preventDefault()
          keyboardNavigableEls[0].focus()
          break

        case keyboardEventKeys.Enter:
          break

        default:
          event.preventDefault()
      }
    }
  }

  /**
   * Applied to all child Menu.Items and submenus to track
   * which Menu.item was last hovered.  Nested menus use this
   * to determine if their trigger is hovered and should be
   * open.
   */
  const menuItemOnMouseEnter = (event: React.MouseEvent<HTMLElement>) => {
    setActiveMenuItem(event.currentTarget)
  }

  const menuOnVisible = () => {
    let selectedItem: HTMLElement | null | undefined = null
    if (focusSelectedOnVisible) {
      selectedItem = menuRef.current?.querySelector(
        '[data-selected-item="true"]',
      )
    }

    if (selectedItem) {
      selectedItem.focus()
    } else {
      focusMenuItem(menuInitialFocus)
    }
  }

  useEffect(() => {
    if (buttonRef.current) {
      buttonRef.current.setAttribute('aria-haspopup', 'true')
      buttonRef.current.setAttribute(
        'id',
        buttonRef.current.getAttribute('id') || buttonId,
      )
    }
    if (menuRef.current) {
      menuRef.current.setAttribute(
        'aria-labelledby',
        buttonRef.current?.getAttribute('id') || buttonId,
      )
    }
  })

  useEffect(() => {
    if (
      buttonRef.current &&
      // Ignore if MenuItemWithSubmenu. In MenuItemWithSubmenu we are not closing the submenu when the user clicks.
      buttonRef.current?.getAttribute('role') !== 'menuitem'
    ) {
      if (menuVisible) {
        buttonRef.current.setAttribute('aria-expanded', 'true')
      } else {
        buttonRef.current.removeAttribute('aria-expanded')
      }
    }
  }, [menuVisible])

  useEventListener(
    'click',
    (event: Event) => {
      const containsTarget = (
        el: HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | null,
      ) => event.target instanceof Node && el?.contains(event.target)

      if (![buttonRef.current, menuRef.current].some(containsTarget)) {
        setMenuVisible(false)
      }
    },
    lazyDocument,
    menuVisible,
  )

  const closeOnEscape = useCallback(() => {
    buttonRef.current?.focus()
    setMenuVisible(false)
  }, [])

  return (
    <EscapeToClose onClose={closeOnEscape} enabled={menuVisible}>
      {children({
        buttonOnClick,
        buttonOnKeyDown,
        buttonRef,
        menuVisible,
        menuAnchor,
        menuRef,
        menuItemOnKeyDown,
        menuItemEventHandler,
        menuOnVisible,
        menuItemOnMouseEnter,
        activeMenuItem,
      })}
    </EscapeToClose>
  )
}

MenuButton.propTypes = {
  children: PropTypes.func.isRequired,
  focusSelectedOnVisible: PropTypes.bool,
  keyboardDirection: PropTypes.oneOf(['horizontal', 'vertical']),
}

MenuButton.defaultProps = {
  focusSelectedOnVisible: false,
  keyboardDirection: 'vertical',
}

MenuButton.displayName = 'Menu.Button'
