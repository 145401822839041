export const escapeRegexSpecials = (text: string): string => {
  // eslint-disable-next-line no-useless-escape
  return text.replace(/([\.\\\+\*\?\[\^\]\$\(\)\{\}\=\!\<\>\|\:\-])/g, '\\$1')
}

/**
 * Normalizes a locale string by replacing underscores with hyphens.
 *
 * @param {string} locale - The locale string to normalize (e.g., 'en_US' or 'fr_CA').
 * @returns {string} - The normalized locale string with hyphens instead of underscores (e.g., 'en-US' or 'fr-CA').
 */
export const normalizeLocale = (locale: string) => {
  return locale.replace('_', '-')
}
